import './css/index.css';
import { useContext, useEffect } from "react";
import { ActionCall } from '../../components/ActionCall/index';
import {VoipModal} from '../../components/VoipModal';
import { VoipBoxCall } from '../../components/VoipBoxCall/index';
import  {Row,Col} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import React,{useState} from 'react'
import Swal from 'sweetalert2';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import caAPI from '../../services/caAPI';

function Call() {
    const [contatoDestino,setContatoDestinoState] = useState('');
    const [showVoipModal,setShowVoipModal] = useState(false);
    const [contatosOrigem,setContatosOrigem] = useState([]);
    const [contatoOrigemChosed,setContatosOrigemChosed] = useState('');
    const {setAtendimentoAtivo} = useContext(AtendimentoContext)

    function setContatoDestino(numberText,flag){
        let phone = '';
        if(contatoDestino.length <= 12 && flag === 'ADD'){
            phone  = contatoDestino+numberText 
        }
    
        if(contatoDestino.length > 0 && flag === 'DEL'){
          phone = contatoDestino.slice(0,-1);
        }

        setContatoDestinoState(phone);
    }

    


    function handleCreateNewCallService() {

        if(contatoOrigemChosed === ''){
            Swal.fire({
                icon:'error',
                title:'Erro!',
                text:'Escolha o contato origem para realizar a ligação'
            })

            return
        }


        if(contatoDestino === ''){
            Swal.fire({
                icon:'error',
                title:'Erro!',
                text:'Escolha o contato no qual você deseja efetuar uma ligação'
            })
            return
        }
        const params = {
            contato_origem:contatoOrigemChosed,
            contato_destino:"+"+contatoDestino,
            meio_comunicacao:'voip',
            tipo_atendimento:'1',
            situacao:'1',
            nome: 'Desconhecido',
            tags:[]
        }

        trackPromise(caAPI.atendimento.create(params))
        .then(response => {
            console.log(response)
            if(response.status === 201 && response.data){
                setAtendimentoAtivo(response.data)
                setShowVoipModal(true)
            }
        }).catch(error => {
            console.log(error)

            Swal.fire({
                icon:"error",
                title:'Ooops!...',
                text:'Não foi possível efetuar a ligação, tente novamente mais tarde'
            })

            return
        })
    }
    

    const getContatosOrigens = () => {
        trackPromise(caAPI.meio_comunicacao.buscarContatoOrigem('voip'))
        .then(response => {
           if(response.status === 200 && response.data){
                setContatosOrigem(response.data)
           }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getContatosOrigens()
    },[])


    return(
        <div color="#F2F3F8" className='call-screen'>
            <div className="row-phone-number">
                <span>Telefone: +{contatoDestino}</span>
            </div>


       
           <div md={12} className='box-select-contato-origem'>
                <label htmlFor="">Contato Origem</label>
                <select 
                name="" 
                id="" 
                className='form-control' 
                onChange={(e) => setContatosOrigemChosed(e.target.value)}>
                        <option value="0">---SELECIONE---</option>
                        {contatosOrigem.map((item,index) => (
                            <option value={item.valor} key={index}>{item.descricao}</option>
                        ))}
                </select>
           </div>
        <VoipBoxCall  setContatoDestino={setContatoDestino}/>

        <ActionCall 
           
            setContatoDestino={setContatoDestino} 
            handleCreateNewCallService={handleCreateNewCallService}
        />
        

        { showVoipModal && <VoipModal contatoDestino={contatoDestino}  setShowVoipModal={setShowVoipModal}/>}
            
        </div>
    );
}



export default Call;