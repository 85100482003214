import Swal from "sweetalert2/src/sweetalert2";
import './index.css';
const errorToast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: false,
	icon:'error',
	background: 'var(--dommus-color-red)',
	customClass: {content: 'alert-toast', icon:'alert-toast'},

	onOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	}
});

const successToast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: false,
	icon:'success',
	background: 'var(--dommus-color-green)',
	customClass: {content: 'alert-toast', icon:'alert-toast'},
	onOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	}
});

const infoToast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: false,
	icon:'info',
	background: 'var(--dommus-color-secondary-light)',
	customClass: {content: 'alert-toast', icon:'alert-toast'},
	onOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	}
});

const warningToast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: true,	
	showDenyButton: true,
	showCancelButton: true,
	timerProgressBar: false,
	icon:'warning',
	background: 'white',
	customClass: {content: 'warning-toast', icon:'warning-toast'},
	onOpen: (toast) => {
	}
});


export {
	errorToast,
	successToast,
	infoToast,
	warningToast
};
