import React from 'react';
import {Modal} from 'react-bootstrap';
import './index.css';


export default function ModalDommus({children, ...props}) {

	return <div>
               {props.open && <div className={`conteudo ${props.classe || ''} modal-ligacao-mobile`}>
                    {props.content}
                    {children}
                </div>}
	    </div>;
}
