
import { useContext, useState } from 'react';
import './css/index.css';






export const VoipBoxCall = (props) => {


    function clickedInNumberBotao(numberText,flag,component){
        component.classList.add('clicked')
        setTimeout(() => {
            component.classList.remove('clicked')
        }, 200);
        props.setContatoDestino(numberText,flag)

    }


    return(
        <div className='box-call-container'>
                <div className='row-call-numbers'>
                    <span className='call-number' onClick={e => clickedInNumberBotao('1','ADD',e.target)}>1</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('2','ADD',e.target)}>2</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('3','ADD',e.target)}>3</span>
                </div>

                <div className='row-call-numbers'>
                    <span className='call-number' onClick={e => clickedInNumberBotao('4','ADD',e.target)}>4</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('5','ADD',e.target)}>5</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('6','ADD',e.target)}>6</span>
                </div>

                <div className='row-call-numbers'>
                    <span className='call-number' onClick={e => clickedInNumberBotao('7','ADD',e.target)}>7</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('8','ADD',e.target)}>8</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('9','ADD',e.target)}>9</span>
                </div>

                <div className='row-call-numbers'>
                    <span className='call-number' onClick={e => clickedInNumberBotao('#','ADD',e.target)}>#</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('0','ADD',e.target)}>0</span>
                    <span className='call-number' onClick={e => clickedInNumberBotao('*','ADD',e.target)}>*</span>
                </div>
            </div>
    )
}