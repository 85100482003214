import {Button,Row,Col} from 'react-bootstrap';
import './css/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH, faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { VoipContext } from './../../contexts/VoipContext.js';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import usuarioIcone from './../../assets/img/usuario.svg'
import { useState,useMemo,useCallback,useEffect} from 'react';
import phoneRing from "../../assets/Audio/modern-phone.mp3";
import caAPI from '../../services/caAPI';
import AtendimentoService from '../../services/AtendimentoService';
import NotificationDommus from "../../helpers/NotificationsHelper";
import { Icon } from '@iconify/react';
import phoneOutgoing from '@iconify-icons/carbon/phone-outgoing';
import phoneBlock from '@iconify-icons/carbon/phone-block';
import { celularMask } from "../InputTextMask";
import { infoToast } from "../../components/DommusToast";
import DommusModal from '../DommusModal';
import { AtendentesContext } from '../../contexts/AtendentesContext';
import Swal from 'sweetalert2';

export const VoipModal = (props) => {
  const [modalAberto, setModalAberto] = useState(false);
  const [dadosChamada, setDadosChamada] = useState({});
  const [idTimeoutReenviarFila, setIdTimeoutReenviarFila] = useState(null);
  const { channel } = useContext(WebSocketContext);
  const { atendimentoAtivo } = useContext(AtendimentoContext)
  const {listaAtendentes} = useContext(AtendentesContext);
  const {inCallStatus, connection, triggerCloseModalIncoming, receiveCall, answerCall, handleRejeitar, hangUp, adicionaChamadaPerdida,handleCall} = useContext(VoipContext);
  const [modalDommus,setModalDommus] = useState(false);
  const [idTimeoutTransferenciaPerdida, setIdTimeoutTransferenciaPerdida] = useState(null);
  const [cacheCallSidTransferencia, setCacheCallSidTransferencia] = useState(null);
  const audio = useMemo(()=>{
    return new Audio(phoneRing);
  },[phoneRing]);

  audio.loop = true;

 
  useEffect(() => {
      modalAberto && connection ? audio.play() : audio.pause();
  }, [audio, modalAberto, connection]);

  const callbackVoip = useCallback(({ data }) => {
    let idUsuario = Number(caAPI.getLocalState('idUsuario'));
    if ((!inCallStatus) && (AtendimentoService.testarUsuarioAtualAtende(data))) {
      NotificationDommus.showNotification(`Você está recebendo uma nova ligação de ${celularMask(data.contato)}`, 'Nova Ligação')
      const callbackTimeout = (data) => {
        if(data.transferencia?.existe){
          registrarTransferenciaPerdida(data);
        }else{
          callbackVoipRedirecionar({data});
        }
        setModalAberto(false);
        setDadosChamada({});
        handleRejeitar();
      };
      const callbackQueVai = callbackTimeout.bind(this, data);
      const timeoutReenviarFila = setTimeout(callbackQueVai, Number(caAPI.getLocalState('tempoLimiteVoipSegundos') || 30) * 1000);
      receiveCall(data.connection_socket, data.id)
      setIdTimeoutReenviarFila(timeoutReenviarFila);
      setModalAberto(true);
      setDadosChamada(data);
    }
  }, [receiveCall, setModalAberto, setDadosChamada, inCallStatus, dadosChamada]);

  const callbackVoipRedirecionar = useCallback(({data}) => {
    let idUsuario = Number(caAPI.getLocalState('idUsuario'));
    if (Number(data.atendente) === idUsuario) {
      adicionaChamadaPerdida(data)
      if(data.transferencia?.existe){
        registrarTransferenciaPerdida(data)
      }else{
        caAPI.atendimento.resend(data.id);
      }
      if(idTimeoutReenviarFila){
        clearTimeout(idTimeoutReenviarFila);
        setIdTimeoutReenviarFila(null);
      }
    }
  }, [idTimeoutReenviarFila]);

  useEffect(() => {
    if(channel && callbackVoip && callbackVoipRedirecionar){
      channel.unbind('voip.1');
      if(!inCallStatus){
        channel.bind('voip.1', callbackVoip);
      }else{
        channel.bind('voip.1', callbackVoipRedirecionar);
      }
    }
    return () => {
      channel && channel.unbind('voip.1');
    }
  }, [channel, inCallStatus, callbackVoip, callbackVoipRedirecionar, idTimeoutReenviarFila,modalAberto]);

  useEffect(() => {
    if(channel){
      channel.unbind('chamada-encerrada');
      if(dadosChamada.id_chamada){
        channel.bind('chamada-encerrada', (data) => {
          if(Number(data.idConteudoAtendimento) === Number(dadosChamada.id_chamada)){
              adicionaChamadaPerdida(data)
              clearTimeout(idTimeoutReenviarFila);
              setIdTimeoutReenviarFila(null);
              setModalAberto(false);
              setDadosChamada({});
              handleRejeitar()
            }
        });
      }
    }

    return () => {
      channel && channel.unbind('chamada-encerrada');
    };
  }, [channel, dadosChamada.id_chamada]);

  useEffect(() => {
    if(channel){
      channel.unbind('voip-assumido');
      if(dadosChamada.id_chamada){
        channel.bind('voip-assumido', (data) => {
            if((Number(data.id_chamada) === Number(dadosChamada.id_chamada)) &&
                Number(data.atendente) !== Number(caAPI.getLocalState('idUsuario'))){
              if(dadosChamada.transferencia?.existe){
                infoToast.fire({text: "Transferência não atendida."})
              }else{
                infoToast.fire({text: "Ligação atendida por outro operador."})
              }
              clearTimeout(idTimeoutReenviarFila);
              setIdTimeoutReenviarFila(null);
              setModalAberto(false);
              setDadosChamada({});
              handleRejeitar()
            }else if((Number(data.id_chamada) === Number(dadosChamada.id_chamada)) &&
            Number(data.atendente) == Number(caAPI.getLocalState('idUsuario'))){
                clearTimeout(idTimeoutReenviarFila);
                setIdTimeoutReenviarFila(null);
            }
        });
      }
    }

    return () => {
      channel && channel.unbind('voip-assumido');
    };
  }, [channel, dadosChamada.id_chamada]);

  useEffect(() => {
    if(triggerCloseModalIncoming){
      setModalAberto(false);
    }
  },[triggerCloseModalIncoming, setModalAberto]);

  useEffect(() => {
    if(((connection && connection._status) === 'closed')){
      hangUp();
    }
  }, [connection, hangUp]);



  function handleRejectCall() {
    if(dadosChamada){
      if(dadosChamada.transferencia?.existe){
        registrarTransferenciaPerdida(dadosChamada, 14);
      }else{
        callbackVoipRedirecionar({data: dadosChamada});
      }
      setDadosChamada({});
    }
    handleRejeitar();
    setModalAberto(false);
  }

  const registrarTransferenciaPerdida = (data, tipoHistorico = 13) => {
    if(data.transferencia?.existe && data.transferencia?.atendente_original){
      caAPI.atendimento.transferenciaNaoAtendida(data.id, data.transferencia.atendente_original, tipoHistorico)
        .then().catch(error => console.log(error));
    }
  };


  const transferirLigacao = () => {

     if(!inCallStatus){
        handleCall()
     }
     setModalDommus(true)
    
  }

  const callbackTransferenciaPerdida = (data) =>{
    if(cacheCallSidTransferencia && (Number(data.id_atendimento) === atendimentoAtivo.id) ){
      if(idTimeoutTransferenciaPerdida){
        clearTimeout(idTimeoutTransferenciaPerdida);
        setIdTimeoutTransferenciaPerdida(null)
      }
      setModalDommus(false);
      setCacheCallSidTransferencia(null)
      infoToast.fire({text: 'Chamada não atendida no destino'});
    }
  };

  function handleTransfer(e) {

    setModalDommus(false);
    
      const data = {atendente: e.target.value}
      let conteudoAtual = Array.isArray(atendimentoAtivo.conteudos) && [...atendimentoAtivo.conteudos].pop();
      let detalhes = conteudoAtual && JSON.parse(conteudoAtual?.detalhes);
     
      setCacheCallSidTransferencia(detalhes?.CallSid);
      caAPI.atendimento.update(data, atendimentoAtivo.id).then(res => {
        const timeoutTransferenciaPerdida = setTimeout(() => {
          caAPI.atendimento.transferenciaNaoAtendida(atendimentoAtivo.id, Number(caAPI.getLocalState("idUsuario")))
            .then(() => {
              callbackTransferenciaPerdida({id_atendimento: atendimentoAtivo.id});
            })
            .catch(error => console.log(error));
        }, ((Number(caAPI.getLocalState('tempoLimiteVoipSegundos') || 30) * 1000) + 10000));
        setIdTimeoutTransferenciaPerdida(timeoutTransferenciaPerdida);
        infoToast.fire({text: 'Transferindo...'});
      }).catch((err) => {
       
        Swal.fire({
            titleText: "Erro",
            text: "Erro ao editar atendimento",
            icon: 'error'
        });
      });
    }

  




  
   
   
    return(
        <>
          <div className='p-3 voip-box'>
            <Row className='my-5'>
                <Col md={12}  className={'d-flex justify-content-center'}>
                   <img className='phone-icon' src={usuarioIcone} alt="" />
                </Col>
            </Row>

            <Row className='my-5'>
                <Col md={12}  className={'d-flex justify-content-center'}>
                   <span className='phone-number-modal'>+{props.contatoDestino}</span>
                </Col>

                <Col md={12}  className={'d-flex justify-content-center'}>
                   <span className='phone-number-modal'>Ligar {dadosChamada.label}</span>
                </Col>

            </Row>

            <div  className='phone-btn-container'>
                <div>
                     <Button variant='success' className='btn-redonly' onClick={() => handleCall()}>
                        <FontAwesomeIcon color='#FFF' icon={faPhone}/>
                    </Button>
                </div>
                <div>
                  
                        <Button onClick={() => {
                          handleRejectCall()
                           props.setShowVoipModal(false)
                        }} className='btn-redonly' variant='danger'>
                                <FontAwesomeIcon color='#FFF' icon={faPhoneSlash}/>
                        </Button>
                </div>
                <div>
               
                     <Button variant='warning' className='btn-redonly' onClick={transferirLigacao}>
                        <FontAwesomeIcon color='#FFF' icon={faArrowsAltH}/>
                    </Button>
                  
                </div>
            </div>
        </div>
            <DommusModal open={ modalDommus} close={setModalDommus}>
                    <select className='form-control' onChange={handleTransfer}>
                        <option value={''}>Selecione o atendente</option>
                        {listaAtendentes.map((item,index) => (
                          <option key={index} value={item.id}>{item.nome}</option>
                        ))}
                    </select>
            </DommusModal>
        </>
    );
}