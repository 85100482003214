import React, { createContext, useState, useEffect} from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const MeioComunicacaoContext = createContext({});

export function MeioComunicacaoProvider({ children }) {
  const [meiosComunicacao, setMeiosComunicacao] = useState([]);
  const [mapaMeioComunicacao, setMapaMeioComunicao] = useState(new Map());

  useEffect(()=>{
    const novoMapa = new Map();
    meiosComunicacao.forEach((meioComunicacao) => {
      novoMapa.set(Number(meioComunicacao.id), meioComunicacao);
    });
    setMapaMeioComunicao(novoMapa);
  }, [meiosComunicacao]);

  const recarregarMeiosComunicacao = ()=>{
    caAPI.meio_comunicacao.list().then(res => {
        setMeiosComunicacao(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os meios de comunicação  " + err,
        icon: 'error'
    }))
  };

  const resolverNomeMeioComunicacao = (idMeioComunicacao, padrao = "Não Atribuído") => {
    let meioComunicacao = mapaMeioComunicacao.get(Number(idMeioComunicacao));
    return (meioComunicacao && meioComunicacao.nome) || padrao;
  };

  return (
    <MeioComunicacaoContext.Provider value={{
      meiosComunicacao,
      recarregarMeiosComunicacao,
      resolverNomeMeioComunicacao
    }}>
      {children}
    </MeioComunicacaoContext.Provider>
  )
}
