import React, {  useContext, useEffect,  useState, useCallback, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalDommus from "../Modal/index";
import { WebSocketContext } from "../../contexts/WebSocketContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Icon } from '@iconify/react';
import phoneOutgoing from '@iconify-icons/carbon/phone-outgoing';
import phoneBlock from '@iconify-icons/carbon/phone-block';
import "./style.scss";
import { AtendimentoContext } from "../../contexts/AtendimentoContext";
import { VoipContext } from "../../contexts/VoipContext";
import { ocultaTelefone } from "../InputTextMask";
import { trackPromise } from "react-promise-tracker";
import caAPI from "../../services/caAPI";
import Swal from "sweetalert2";
import NotificationDommus from "../../helpers/NotificationsHelper";
import { celularMask } from "../InputTextMask";
import { infoToast } from "../DommusToast";
import AtendimentoService from "../../services/AtendimentoService";
import phoneRing from "../../assets/Audio/modern-phone.mp3"

export default function RecebeLigacaoVoipModal() {
  const [modalAberto, setModalAberto] = useState(false);
  const [dadosChamada, setDadosChamada] = useState({});
  const [idTimeoutReenviarFila, setIdTimeoutReenviarFila] = useState(null);
  const { handleAdiconaLista, setAtendimentoAtivo } = useContext(AtendimentoContext);
  const { channel } = useContext(WebSocketContext);
  const {inCallStatus, connection, triggerCloseModalIncoming, receiveCall, answerCall, handleRejeitar, hangUp, adicionaChamadaPerdida} = useContext(VoipContext);

  const audio = useMemo(()=>{
    return new Audio(phoneRing);
  },[phoneRing]);

  audio.loop = true;

  const handleModalFechaModal = function () {
    setModalAberto(value => !value)
  };

  useEffect(() => {
      modalAberto && connection ? audio.play() : audio.pause();
  }, [audio, modalAberto, connection]);

  const callbackVoip = useCallback(({ data }) => {
    let idUsuario = Number(caAPI.getLocalState('idUsuario'));
    if ((!inCallStatus) && (AtendimentoService.testarUsuarioAtualAtende(data))) {
      NotificationDommus.showNotification(`Você está recebendo uma nova ligação de ${celularMask(data.contato)}`, 'Nova Ligação')
      const callbackTimeout = (data) => {
        if(data.transferencia?.existe){
          registrarTransferenciaPerdida(data);
        }else{
          callbackVoipRedirecionar({data});
        }
        setModalAberto(false);
        setDadosChamada({});
        handleRejeitar();
      };
      const callbackQueVai = callbackTimeout.bind(this, data);
      const timeoutReenviarFila = setTimeout(callbackQueVai, Number(caAPI.getLocalState('tempoLimiteVoipSegundos') || 30) * 1000);
      receiveCall(data.connection_socket, data.id)
      setIdTimeoutReenviarFila(timeoutReenviarFila);
      setModalAberto(true);
      setDadosChamada(data);
    }
  }, [receiveCall, setModalAberto, setDadosChamada, inCallStatus, dadosChamada]);

  const callbackVoipRedirecionar = useCallback(({data}) => {
    let idUsuario = Number(caAPI.getLocalState('idUsuario'));
    if (Number(data.atendente) === idUsuario) {
      adicionaChamadaPerdida(data)
      if(data.transferencia?.existe){
        registrarTransferenciaPerdida(data)
      }else{
        caAPI.atendimento.resend(data.id);
      }
      if(idTimeoutReenviarFila){
        clearTimeout(idTimeoutReenviarFila);
        setIdTimeoutReenviarFila(null);
      }
    }
  }, [idTimeoutReenviarFila]);

  useEffect(() => {
    if(channel && callbackVoip && callbackVoipRedirecionar){
      channel.unbind('voip.1');
      if(!inCallStatus){
        channel.bind('voip.1', callbackVoip);
      }else{
        channel.bind('voip.1', callbackVoipRedirecionar);
      }
    }
    return () => {
      channel && channel.unbind('voip.1');
    }
  }, [channel, inCallStatus, callbackVoip, callbackVoipRedirecionar, idTimeoutReenviarFila,modalAberto]);

  useEffect(() => {
    if(channel){
      channel.unbind('chamada-encerrada');
      if(dadosChamada.id_chamada){
        channel.bind('chamada-encerrada', (data) => {
          if(Number(data.idConteudoAtendimento) === Number(dadosChamada.id_chamada)){
              adicionaChamadaPerdida(data)
              clearTimeout(idTimeoutReenviarFila);
              setIdTimeoutReenviarFila(null);
              setModalAberto(false);
              setDadosChamada({});
              handleRejeitar()
            }
        });
      }
    }

    return () => {
      channel && channel.unbind('chamada-encerrada');
    };
  }, [channel, dadosChamada.id_chamada]);

  useEffect(() => {
    if(channel){
      channel.unbind('voip-assumido');
      if(dadosChamada.id_chamada){
        channel.bind('voip-assumido', (data) => {
            if((Number(data.id_chamada) === Number(dadosChamada.id_chamada)) &&
                Number(data.atendente) !== Number(caAPI.getLocalState('idUsuario'))){
              if(dadosChamada.transferencia?.existe){
                infoToast.fire({text: "Transferência não atendida."})
              }else{
                infoToast.fire({text: "Ligação atendida por outro operador."})
              }
              clearTimeout(idTimeoutReenviarFila);
              setIdTimeoutReenviarFila(null);
              setModalAberto(false);
              setDadosChamada({});
              handleRejeitar()
            }else if((Number(data.id_chamada) === Number(dadosChamada.id_chamada)) &&
            Number(data.atendente) == Number(caAPI.getLocalState('idUsuario'))){
                clearTimeout(idTimeoutReenviarFila);
                setIdTimeoutReenviarFila(null);
            }
        });
      }
    }

    return () => {
      channel && channel.unbind('voip-assumido');
    };
  }, [channel, dadosChamada.id_chamada]);

  useEffect(() => {
    if(triggerCloseModalIncoming){
      setModalAberto(false);
    }
  },[triggerCloseModalIncoming, setModalAberto]);

  useEffect(() => {
    if(((connection && connection._status) === 'closed')){
      hangUp();
    }
  }, [connection, hangUp]);

  function handleAnswerCall() {
    if(idTimeoutReenviarFila){
      clearTimeout(idTimeoutReenviarFila);
      setIdTimeoutReenviarFila(null);
    }
    answerCall();
    setModalAberto(false);
  }

  function handleRejectCall() {
    if(dadosChamada){
      if(dadosChamada.transferencia?.existe){
        registrarTransferenciaPerdida(dadosChamada, 14);
      }else{
        callbackVoipRedirecionar({data: dadosChamada});
      }
      setDadosChamada({});
    }
    handleRejeitar();
    setModalAberto(false);
  }

  const registrarTransferenciaPerdida = (data, tipoHistorico = 13) => {
    if(data.transferencia?.existe && data.transferencia?.atendente_original){
      caAPI.atendimento.transferenciaNaoAtendida(data.id, data.transferencia.atendente_original, tipoHistorico)
        .then().catch(error => console.log(error));
    }
  };

  return (
    
    <div>
      {/*<audio src="/phone-ringing.mp3" ref={audio}></audio>*/}
      <ModalDommus size="sm" backdrop="static" zindex="1080" open={(!inCallStatus) && connection && modalAberto} close={handleModalFechaModal} titulo="Ligação Recebida">
        <div className="corpoLigacaoRecebida">
          <div className="info">
            <div className="fotoEnvolvido">
              <FontAwesomeIcon icon={faUserCircle} color="#444444" />
            </div>
          </div>
          <br />
          <div className="text-center">

            {dadosChamada.nome ?? dadosChamada.nome}
            <br />
            <b>{ocultaTelefone(dadosChamada.contato)}</b>
          </div>
          <br />
          <div className="stage">
            <div className="dot-elastic" />
          </div>
          {true &&
            <div className="botoesAcao">

              <OverlayTrigger key="buttonAtender" placement="top" overlay={<Tooltip id={`tooltip-atender`}>Atender</Tooltip>}>
                <button className="botaoAtender" onClick={handleAnswerCall}>
                  <Icon icon={phoneOutgoing} width="1em" />
                </button>
              </OverlayTrigger>

              <OverlayTrigger key="buttonRejeitar" placement="top" overlay={<Tooltip id={`tooltip-rejeitar`}>Rejeitar</Tooltip>}>
                <button className="botaoRejeitar" onClick={handleRejectCall}>
                  <Icon icon={phoneBlock} width="1em" />
                </button>
              </OverlayTrigger>

            </div>
          }
          <p className="text-center">Recebendo Ligação: {dadosChamada.label}</p>
        </div>
      </ModalDommus>
    </div >

  )
}
