import React, { createContext, useState } from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const SituacaoAtendimentoContext = createContext({});

export function SituacaoAtendimentoProvider({ children }) {
  const [situacoesAtendimento, setSituacoesAtendimento] = useState([]);

  const recarregarSituacoesAtendimento = ()=>{
    caAPI.situacao.list().then(res => {
        setSituacoesAtendimento(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar as situações de atendimento  " + err,
        icon: 'error'
    }))
  };

  return (
    <SituacaoAtendimentoContext.Provider value={{
      situacoesAtendimento,
      recarregarSituacoesAtendimento
    }}>
      {children}
    </SituacaoAtendimentoContext.Provider>
  )
}
