import React, { createContext, useState } from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const TipoAtendimentoContext = createContext({});

export function TipoAtendimentoProvider({ children }) {
  const [tiposAtendimento, setTiposAtendimento] = useState([]);

  const recarregarTiposAtendimento = ()=>{
    caAPI.tipo_atendimento.list().then(res => {
        setTiposAtendimento(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os tipos de atendimento  " + err,
        icon: 'error'
    }))
  };

  return (
    <TipoAtendimentoContext.Provider value={{
      tiposAtendimento,
      recarregarTiposAtendimento
    }}>
      {children}
    </TipoAtendimentoContext.Provider>
  )
}
