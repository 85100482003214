import { trackPromise } from 'react-promise-tracker';
import api from './api';
import moment from "moment";
import formatDommus from '../helpers/format';

const setLocalState = (name, value) => {
  localStorage.setItem('@DommusCA-' + name, JSON.stringify(value));
};
const getLocalState = name => {
  return JSON.parse(localStorage.getItem('@DommusCA-' + name));
};

function checkPermissaoExists(obj, level, ...rest) {
  if (obj === undefined) return false
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true
  return checkPermissaoExists(obj[level], ...rest)
}

const prepararFiltros = (filtrosEntrada) => {
  let dataAtual = moment().format("YYYY-MM-DD");
  let filtros = filtrosEntrada;
  if (!(filtrosEntrada && filtrosEntrada.data_inicio)) {
    filtros = Object.assign({}, { data_inicio: dataAtual, data_fim: dataAtual }, filtrosEntrada || {})
  }
  let filtrosQueRealmenteVai = Object.assign({}, filtros);
  if (filtros && filtros.data_inicio) {
    let dataAux = ((filtros.data_inicio instanceof Date) && filtros.data_inicio) || new Date(filtros.data_inicio);
    filtrosQueRealmenteVai.data_inicio = formatDommus.dateToLocalISO(dataAux).split('T')[0];
  }
  if (filtros && filtros.data_fim) {
    let dataAuxFim = ((filtros.data_fim instanceof Date) && filtros.data_fim) || new Date(filtros.data_fim);
    filtrosQueRealmenteVai.data_fim = formatDommus.dateToLocalISO(dataAuxFim).split('T')[0];
  }
  if(filtrosQueRealmenteVai.data_criacao_inicio && filtrosQueRealmenteVai.data_criacao_fim) {
    delete filtrosQueRealmenteVai.data_fim
    delete filtrosQueRealmenteVai.data_inicio
  }
  return filtrosQueRealmenteVai;
}

const caAPI = {
  setLocalState,
  getLocalState,

  logout: () => {
    localStorage.removeItem('@DommusCA-token');
    localStorage.removeItem('@DommusCA-telas');
    localStorage.removeItem('@DommusCA-idUsuario');
    localStorage.removeItem('@DommusCA-tipoUsuario');
    localStorage.removeItem('@DommusCA-nomeUsuario');
    localStorage.removeItem('@DommusCA-permissoes');
    localStorage.removeItem('@DommusCA-contatosVeTudo');
    localStorage.removeItem('@DommusCA-visualizaNaoAtendidos');
    localStorage.removeItem('@DommusCA-limpaBacia');
    localStorage.removeItem('@DommusCA-tempoLimiteVoipSegundos');

  
  },

  setLoginInfo: data => {
    setLocalState('token', data.access_token);
    setLocalState('nomeUsuario', data.nome_usuario);
    setLocalState('idUsuario', data.id_usuario);
    setLocalState('idCliente', data.id_cliente);
    setLocalState('tipoUsuario', data.id_usuario_tipo);
    setLocalState('permissoes', data.permissoes);
    setLocalState('contatosVeTudo', data.atendimentos_contato_origem);
    setLocalState('visualizaNaoAtendidos', data.visualiza_atendimentos_nao_atendidos);
    setLocalState('limpaBacia', data.limpa_bacia);
    setLocalState('tempoLimiteVoipSegundos', data.tempo_limite_voip_segundos);
  },

  validaToken: token => {
    return api.post('gu-api/valida-token', {
      token
    });
  },

  barraTopo: function() {
    return api.get('/estatistica/index');
  },
  meio_comunicacao: {
    list: () => {
      return api.get('/meio-comunicacao')
    },
    getTemplate: (id_meio_comunicacao) => {
      return api.get(`/meio-comunicacao/templates/${id_meio_comunicacao}`)
    },
    getTemplatesAprovados: (slug_meio_comunicacao) => {
      return api.get(`/meio-comunicacao/templates/${slug_meio_comunicacao}-aprovados`)
    },
    buscarContatoOrigem: (slug_meio_comunicacao) => {
      return api.get(`/meio-comunicacao/contato/${slug_meio_comunicacao}`);
    },
    buscarModelosModulo:(idMeioComunicacao, idModulo) => {
      return api.get(`meio-comunicacao/${idMeioComunicacao}/modulo/${idModulo}/modelo`);
    },
    buscarTagsModelo:(idMeioComunicacao, idModulo, idModelo) => {
      return api.get(`meio-comunicacao/${idMeioComunicacao}/modulo/${idModulo}/modelo/${idModelo}/tags`);
    }
  },
  relatorios: {
    list: () =>{
      return api.get('/relatorios')
    }
  },
  situacao: {
    list: () => {
      return api.get('/situacao')
    }
  },
  tipo_conteudo: {
    list: () => {
      return api.get('/tipo-conteudo')
    }
  },
  tipo_atendimento: {
    list: () => {
      return api.get('/tipo-atendimento')
    }
  },
  tipo_historico_atendimento: {
    list: () => {
      return api.get('/tipo-historico-atendimento')
    }
  },
  template: {
    list: () => {
      return api.get(`/template`)
    },
    buscaPorId: (id_template) => {
      return api.get(`/template/${id_template}`)
    },
    create: (template, id_atendimento) => {
      if (id_atendimento) {
        return api.put(`/template/${id_atendimento}`, template)
      } else {
        return api.post(`/template`, template)
      }

    },
    atualizar: (template) => {
      return api.put(`/template/${template.id}`, {
        nome: template.nome,
        tipo_atendimento: template.tipo_atendimento,
        meio_comunicacao: template.meio_comunicacao,
        mensagem: template.mensagem
      })
    },
    deletar: (id_template) => {
      return api.delete(`/template/${id_template}`)
    },
    buscarTiposAnexos: () => {
      return api.get("/template/buscarTiposAnexos");
    },
    uploadBase64: (arquivo, nomeArquivo=null) => {
      return api.post('/template/arquivo', { arquivo, nomeArquivo })
    }
  },

  labels: {
    list: () => {
      return api.get(`/label`)
    },
    update: (id, value) => {
      return api.put(`/label/${id}`, {
        label: value
      })
    },
  },
  tags:{
    list: () => {
      return api.get('/tag');
    },
    listTagsAtendimentoDistintos: () =>{
      return api.get('/tag-atendimento/distintos')
    },
    create: (value) => {
      let data = {
        nome: value
      }
      return api.post('/tag', data);
    },
    removerTagsAtendimento: (tags)=>{
      return api.put('/tag-atendimento/remove-tags-atendimentos', tags);
    }
  },
  atendimento: {
    resend: (idAtendimento) => {
      return api.get(`/atendimento/${idAtendimento}/reenviar-fila`)
    },
    list: (date = null) => {
      return api.get('/atendimento?date=' + date);
    },
    getMessages: (id, page = 1) => {
      return api.get(`/atendimento/${id}/mensagens/${page}`);
    },
    find: id => {
      return api.get(`/atendimento/${id}`);
    },
    findWithoutReading: id => {
      return api.get(`/atendimento/sem-ler/${id}`);
    },
    create: (data, idAtendimento) => {
      if (!idAtendimento) {
        return api.post(`/atendimento`, data);
      } else {
        return api.put(`/atendimento/${idAtendimento}/atender`, data);
      }
    },
    atender: (data, idAtendimento) => {
      return api.put(`/atendimento/${idAtendimento}/atender`, data);
    },
    update: (data, idAtendimento) => {
      return api.put(`/atendimento/${idAtendimento}`, data);
    },
    arquivado: {
      list: (page = 1, tamanho = 10) => {
        return api.get(`/atendimento/arquivados`, {
          params: {
            pagina: page,
            limite: tamanho
          }
        })
      },
      total: () => {
        return api.get(`/atendimento/arquivados/total`)
      },
      desarquivar: id => {
        return api.put(`/atendimento/arquivados/recuperar/${id}`);
      },
      historico: (id) => {
        return api.get(`/atendimento/${id}/historico`)
      }
    },
    anotacao: {
      list: (id_atendimento) => {
        return api.get(`/atendimento/anotacao/${id_atendimento}`)
      },
      enviar: (id_atendimento, conteudo) => {
        return api.post(`/atendimento/anotacao/${id_atendimento}`, {
          anotacao: conteudo
        })
      },
      atualizar: (id_atendimento, id_anotacao, conteudo) => {
        return api.put(`/atendimento/anotacao/${id_atendimento}/${id_anotacao}`, {
          "anotacao": conteudo
        })
      },
      deletar: (id_atendimento, id_anotacao) => {
        return api.delete(`/atendimento/anotacao/${id_atendimento}/${id_anotacao}`)
      }

    },
    sms: {
      enviar: (id_atendimento, data) => {
        return api.post(`/atendimento/sms/${id_atendimento}`, data);
      },
      buscar: contato => {
        return api.post('/atendimento/sms/listar-por-numero', {
          "contato_destino": contato
        })
      },
      dispararTemplate: (idTemplate, contatos, separador = ";") =>{
        return api.post(`/atendimento/sms/enviar-massa/${idTemplate}`, {
          "base64_destinatarios": btoa(contatos),
          "separador": separador
        })
      }
    },
    email: {
      enviar: data => {
        return api.post('/atendimento/email', data);
      },
      buscar: id => {
        return api.get(`/atendimento/${id}`)
      },
      getAnexo: file => {
        return api.get(`/atendimento/email/anexo/${file}`);
      },
      getAssinatura: () => {
        return api.get('/usuario/assinatura');
      }
    },
    chatbot: {
      enviar: (data) => {
        return api.post('/send/blip', data);
      }
    },
    reclameAqui: {
      enviar: (data) => {
        return api.post('/ra/send', data)
      }
    },
    facebookMessenger: {
      send: (data) => {
        return api.post('/facebook/send', data)
      },
      sendImage: (data) => {
        return api.post('/facebook/send/image', data)
      }
    },
    whatsapp: {
      iniciar: data => {
        return api.post('/atendimento/whatsapp/iniciar-atendimento', data);
      },
      enviar: data => {
        return api.post('/atendimento/whatsapp', data)
      },
      templates: () => {
        return api.get('/meio-comunicacao/templates/whatsapp')
      },
      templatesAprovados: () => {
        return api.get('/meio-comunicacao/templates/whatsapp-aprovados')
      }
    },
    voip: {
      buscarGravacao:(callSid)=> {
        return api.get(`/atendimento/voip/gravacao/${callSid}`);
      },
      iniciarChamada: (id_atendimento, call_sid)=> {
        return api.get(`/atendimento/voip/iniciar/${id_atendimento}/${call_sid}`);
      },
      novoIniciarChamada: (id_atendimento, call_sid)=> {
        return api.get(`/atendimento/voip/novo-iniciar/${id_atendimento}/${call_sid}`);
      },
      salvarDuracao: (id_atendimento, id_chamada, duracao) => {
        return api.post(`/atendimento/voip/ligacao/${id_atendimento}/${id_chamada}/duracao`, {
          duracao:duracao
        });
      },
      // dont use.

      listaNumerosCliente: () => {
        return api.get('/atendimento/voip/numeros-cliente');
      },
    },
    transferenciaNaoAtendida: (id, atendenteOriginal, tipoHistorico = 13) => {
      return api.post(`/atendimento/${id}/transferencia-perdida`,{
        atendente_original: atendenteOriginal,
        tipo_historico: tipoHistorico
      });
    },
    processo: {
      buscaPorId: id_processo => {
        return api.get(`/atendimento/detalhes/getByProcesso/${id_processo}`);
      },
      buscarPorInfo: info => {
        return api.post(`/atendimento/detalhes/buscar`, {
          search: info
        });
      },
      salvaProcesso: (id_atendimento, data) => {
        return api.put(`/atendimento/detalhes/alterar/${id_atendimento}`, data);
      }
    },
    opv: {
      buscaPorId: id_opv => {
        return api.get(`/atendimento/detalhes/getByOportunidade/${id_opv}`);
      }
    },
    lead: {
      enviaLead: (id_atendimento, campanha, empreendimento, data) => {
        return api.post(`/atendimento/lead/${id_atendimento}/${campanha}/${empreendimento}`, {
            data
        });
      }
    },
    atendentes: {
      listaAtendentes: () => {
        return api.get('/atendentes')
      },
      listaAtendentesTransferencia: () => {
        return api.get('/atendentes-transferencia')
      }
    },
    filtro: {
      filtrar: (filtros, page = 1) => {
        const filtrosQueRealmenteVai = prepararFiltros(filtros);
        return api.get(`/atendimento`, {
          params: {
            filtros: filtrosQueRealmenteVai,
            pagina: page,
          }
        })
      },
      total: (filtros) => {
        const filtrosQueRealmenteVai = prepararFiltros(filtros);
        return api.get(`/atendimento/total`, {
          params: {
            filtros: filtrosQueRealmenteVai
          }
        })
      },
      exportar: (filtros, exportar) => {
        const filtrosQueRealmenteVai = prepararFiltros(filtros);
        return api.get(`/atendimento/exportar`, {
          params: {
            filtros: filtrosQueRealmenteVai,
            exportar: true
          }
        })
      },
    },
    registroOutrosAtendimentos:{
      buscar: (data) => {
        return api.get(`/atendimento/registros-outros?contato_destino=${data?.contato_destino}&processo=${data?.processo}&oportunidade=${data?.oportunidade}&tamanho_pagina=${data?.tamanho_pagina}&page=${data?.page}`);
      },
    },
  },
  perfil:{
    buscarVistoUltimo:() => {
    return api.get("/perfil-usuario/visto-ultimo");
  },
    tempoOnline: (id, filtro) => {
    return api.get(`/perfil-usuario/tempo-online`, {params: {
      filtro: filtro,
      usuarios: id
    }})
  },
  },

  loginGU: token => {
    return api.get(`/gu-api/login/${token}`);
  },
  getTokenCcd: function() {
    return api.post('/atendimento/voip/get-token');
  },
  makeCall: function(number) {
    return api.post('/make-call', {
      number
    });
  },
  checkPermissaoExists,
  permissao: {
    pode: path => {
      const jsonPermissao = JSON.parse(getLocalState('permissoes'));
      const arrayPath = path.split('.');
      if (!checkPermissaoExists(jsonPermissao, [arrayPath[0]], [arrayPath[1]], [arrayPath[2]])) {
        return false
      }
      return jsonPermissao[arrayPath[0]][arrayPath[1]][arrayPath[2]];
    }
  },
  bacia:{
    buscar: () => {
      return api.get("/bacia-atendimento");
    },
    recuperar: () => {
      return api.get("/bacia-atendimento/recuperar-atendimento");
    },
    limpar: ()=>{
      return api.delete('/bacia-atendimento');
    },
  },
  modulo:{
    buscar: () => {
      return api.get("/modulo");
    },
  },
  historicoAtendimento:{
    total: (filtros = {}, filtrarAtual = true, filtrarSnapshot = true) => {
      return api.get("/historico-atendimento/total", {
        params: {
          filtros: filtros,
          filtrar_atual: filtrarAtual,
          filtrar_snapshot: filtrarSnapshot
        }
      })
    },
    index: (pagina = 1, limite = 20, filtros = {}, filtrarAtual = true, filtrarSnapshot = true) => {
      return api.get("/historico-atendimento", {
        params: {
          pagina: pagina,
          limite: limite,
          filtros : filtros,
          filtrar_atual: filtrarAtual,
          filtrar_snapshot: filtrarSnapshot
        }
      });
    },
    show:(id)=>{
      return api.get(`/historico-atendimento/${id}`);
    }
  },
  arquivoAtendimento: {
    buscar: (filtros) => {
      return trackPromise(api.get('/arquivo-atendimento', { params: filtros }))
    }, 
    salvar: (arquivos) => {
      return trackPromise(api.post('/arquivo-atendimento', arquivos))
    },
    excluir: (idArquivo) => {
      return trackPromise(api.delete(`/arquivo-atendimento/${idArquivo}`))
    }
  }
};

export default caAPI;
