import React from 'react';
import {Modal} from 'react-bootstrap';
import './css/index.css';

const DommusModal = ({children, ...props}) => {

	return <Modal show={props.open} onHide={props.close} size={props.size || "lg"}
								centered
								backdrop={(props.backdrop || true)}
								style={{
									zIndex: props.zindex ||'1000000000',
									paddingRight: '17px',
									display: 'block'
								}}>
		<Modal.Header closeButton>
			<Modal.Title><h2>{props.titulo}</h2></Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className={`conteudo ${props.classe || ''}`}>
				{props.content}
				{children}
			</div>
		</Modal.Body>
	</Modal>;
}


export default DommusModal;
