import { faPhone, faBackspace } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './css/index.css';




export const ActionCall = (props) => {
    return(
        <div className='make-or-decline-call'>
                    <FontAwesomeIcon
                    onClick={() => {
                        props.handleCreateNewCallService()
                      } }
                    size='2x' icon={faPhone} color='#FFF'/>
                    <FontAwesomeIcon size='2x' onClick={() => props.setContatoDestino('','DEL')} icon={faBackspace} color='#ffffff'/>
                </div>
    )
}